import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Building2, User } from 'lucide-react'

import ContactMenu from '@/components/contact-details/contact-menu.tsx'

import { ContactTypes } from '@/constants.ts'
import type { ContactDetailsResponse } from '@/types.ts'

type ContactCardProps = {
  contactDetails: ContactDetailsResponse
}
export default function ContactCard({ contactDetails }: ContactCardProps) {
  const { contact } = contactDetails
  const contactDetailsDisplay =
    contact.type === ContactTypes.USER ? (
      <>
        <Typography variant="h3">
          {contact.first_name} {contact.last_name}
        </Typography>
        <Box>
          <Typography variant="body1">{contact.phone?.[0]?.number}</Typography>
          <Typography variant="body1">{contact.email?.[0]?.address}</Typography>
        </Box>
      </>
    ) : (
      <>
        <Typography variant="h3">{contact.organization_name}</Typography>
        {contact.address && contact.address.length > 0 && (
          <Box>
            <Typography variant="body1">
              {contact.address[0].address.street}
            </Typography>
            <Typography variant="body1">
              {contact.address[0].address.city},{' '}
              {contact.address[0].address.state}{' '}
              {contact.address[0].address.zip}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="body1">{contact.phone?.[0]?.number}</Typography>
          <Typography variant="body1">{contact.email?.[0]?.address}</Typography>
        </Box>
      </>
    )
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap={2}>
        <Avatar variant="rounded" sx={{ width: 80, height: 80 }}>
          {contact.type === ContactTypes.USER ? (
            <User size={48} />
          ) : (
            <Building2 size={48} />
          )}
        </Avatar>
        <Box display="flex" flexDirection="column" gap={2}>
          {contactDetailsDisplay}
        </Box>
      </Box>
      <Box>
        <ContactMenu contactDetails={contactDetails} />
      </Box>
    </Box>
  )
}
