import type { GCSFile } from '@/types.ts'

import api from './api.ts'

export async function createFile(file: Partial<GCSFile>) {
  const response = await api.post('/v1/mdb/files/', file, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as GCSFile
}

export async function deleteFile(fileId: string) {
  const response = await api.delete(`/v1/mdb/files/${fileId}`)
  return response.status
}
