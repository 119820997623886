import { isAxiosError } from 'axios'
import * as Sentry from '@sentry/react'

export function logError(error: unknown) {
  if (isAxiosError(error)) {
    Sentry.captureException(error, {
      extra: {
        status: error.response?.status,
        statusText: error.response?.statusText,
        responseData: error.response?.data,
      },
    })
  } else {
    Sentry.captureException(error)
  }

  // only log the error to the console in development
  if (import.meta.env.VITE_ENV === 'development') {
    console.error('API Error encountered:', error)
  }
}
