import { Organization } from '@/types.ts'
import React, { createContext, ReactNode, useContext, useState } from 'react'

type OrganizationContextType = {
  organizations: Organization[] | null
  selectedOrganization: Organization | null
  setSelectedOrganization: (organization: Organization | null) => void
}

const OrganizationContext = createContext<OrganizationContextType | undefined>(
  undefined
)

type OrganizationProviderProps = {
  children: ReactNode
  organizations: Organization[] | null
}
export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
  organizations,
}) => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(organizations?.[0] || null)

  return (
    <OrganizationContext.Provider
      value={{ selectedOrganization, setSelectedOrganization, organizations }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = () => {
  const context = useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider'
    )
  }
  return context
}
