import Modal from '@mui/material/Modal'
import BlurredBackdrop from '@/components/ui/modals/blurred-backdrop.tsx'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Settings, X } from 'lucide-react'
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'
import SettingsAndMembersTabs from '@/components/ui/modals/settings-and-members-tabs.tsx'

type SettingsAndMembersModalProps = {
  open: boolean
  onClose: () => void
}
export default function SettingsAndMembersModal({
  open,
  onClose,
}: SettingsAndMembersModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: BlurredBackdrop }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1108,
          height: 963,
          bgcolor: 'white',
          boxShadow: 24,
          borderRadius: 2,
          pt: 2,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Settings size={26} strokeWidth={2.5} />
            <Typography variant="h2" fontWeight="bold">
              Settings and Members
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <X size={24} />
          </IconButton>
        </Box>
        <Divider />
        <SettingsAndMembersTabs />
      </Box>
    </Modal>
  )
}
