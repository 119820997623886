import { type ChangeEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { US_STATES } from '@/constants.ts'
import type { Address, ContactDetails, Email, Phone } from '@/types.ts'

type NewContactOrganizationFormProps = {
  contactDetails: ContactDetails
  onContactDetailsChange: (newDetails: Partial<ContactDetails>) => void
}
export default function ContactOrganizationForm({
  contactDetails,
  onContactDetailsChange,
}: NewContactOrganizationFormProps) {
  const [phoneNumbers, setPhoneNumbers] = useState(
    contactDetails.phone && contactDetails.phone.length
      ? contactDetails.phone
      : [{ number: '' }]
  )
  const [emailAddresses, setEmailAddresses] = useState(
    contactDetails.email && contactDetails.email.length
      ? contactDetails.email
      : [{ address: '' }]
  )

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number = 0
  ) => {
    const { name, value } = event.target

    const updateContactDetail = (
      field: 'phone' | 'email',
      updateType: boolean = false
    ) => {
      const currentDetails = field === 'phone' ? phoneNumbers : emailAddresses
      const updatedDetails = [...currentDetails]
      const currentDetail = updatedDetails[index] || {}

      if (updateType) {
        updatedDetails[index] = {
          ...currentDetail,
          contact_type: value as 'work' | 'mobile' | 'other',
        } as Phone | Email
      } else {
        updatedDetails[index] =
          field === 'phone'
            ? ({ ...currentDetail, number: value } as Phone)
            : ({ ...currentDetail, address: value } as Email)
      }

      if (field === 'phone') {
        setPhoneNumbers(updatedDetails as Phone[])
      } else {
        setEmailAddresses(updatedDetails as Email[])
      }

      return {
        [field]: updatedDetails,
      }
    }

    const updateAddress = (
      field: 'street1' | 'street2' | 'city' | 'state' | 'zip'
    ) => {
      const currentAddress = contactDetails.address
      const updatedAddress = {
        ...currentAddress,
        [field]: value,
      } as Address

      return { address: updatedAddress }
    }

    const inputHandlers: Record<string, () => Partial<ContactDetails>> = {
      phone_number: () => updateContactDetail('phone'),
      phone_type: () => updateContactDetail('phone', true),
      email_address: () => updateContactDetail('email'),
      email_type: () => updateContactDetail('email', true),
      address_street1: () => updateAddress('street1'),
      address_street2: () => updateAddress('street2'),
      address_city: () => updateAddress('city'),
      address_state: () => updateAddress('state'),
      address_zip: () => updateAddress('zip'),
      default: () => ({ [name]: value }),
    }

    const handler = inputHandlers[name] || inputHandlers.default
    onContactDetailsChange(handler())
  }

  return (
    <Box>
      <TextField
        variant="outlined"
        name="organization_name"
        label="Organization Name"
        placeholder="Organization Name"
        fullWidth
        required
        value={contactDetails.organization_name || ''}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <Typography variant="subtitle1" gutterBottom>
        Address
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="address_street1"
            label="Address Line 1"
            placeholder="123 Main"
            fullWidth
            value={contactDetails.address?.street1 || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="address_street2"
            label="Address Line 2"
            placeholder="Suite #"
            fullWidth
            value={contactDetails.address?.street2 || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="address_city"
            label="City"
            placeholder="City Name"
            fullWidth
            value={contactDetails.address?.city || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="address_state"
            label="State"
            fullWidth
            select
            value={contactDetails.address?.state || ''}
            onChange={handleInputChange}
          >
            {US_STATES.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="address_zip"
            label="Zip Code"
            fullWidth
            value={contactDetails.address?.zip || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" gutterBottom>
        Contact Details
      </Typography>
      {phoneNumbers.map((phone, index) => (
        <Grid container spacing={2} key={index} mb={1}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              name="phone_number"
              label="Phone"
              placeholder="(---) --- ----"
              fullWidth
              value={phone.number || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              name="phone_type"
              label="Type"
              fullWidth
              select
              value={contactDetails.phone?.[index]?.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={() =>
          setPhoneNumbers((prev) => {
            const newPhone: Phone = { number: '' }
            return [...prev, newPhone]
          })
        }
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Phone
        </Typography>
      </Button>
      {emailAddresses.map((email, index) => (
        <Grid container spacing={2} key={index} mb={1}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              name="email_address"
              label="Email"
              placeholder="info@acme.co"
              fullWidth
              value={email.address || ''}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              name="email_type"
              label="Type"
              fullWidth
              select
              value={contactDetails.email?.[index]?.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={() =>
          setEmailAddresses((prev) => {
            const newEmail: Email = { address: '' }
            return [...prev, newEmail]
          })
        }
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Email
        </Typography>
      </Button>
    </Box>
  )
}
