import { createFileRoute } from '@tanstack/react-router'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  return (
    <Box>
      <Typography variant="h1">Welcome to Plantbid v1.5</Typography>
    </Box>
  )
}
