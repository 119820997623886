import axios from 'axios'

/**
 * Uploads a file to the specified URL using the global Axios instance.
 *
 * IMPORTANT: This function MUST use the global Axios instance, not the custom API instance. The global instance is
 * required for proper file uploads to Google Cloud Storage. Using the custom instance will result in the upload
 * failing and showing up as a CORS error in the browser console.
 *
 * @param file The file to upload
 * @param contentType The content type of the file
 * @param putUrl The URL to upload the file to, should be a signed URL from Google Cloud Storage
 */
export async function uploadFile({
  file,
  contentType,
  putUrl,
}: {
  file: File
  contentType: string
  putUrl: string
}) {
  const response = await axios.put(putUrl, file, {
    headers: {
      'Content-Type': contentType,
    },
  })
  return response.status
}
