import {
  Outlet,
  createFileRoute,
  useRouteContext,
} from '@tanstack/react-router'
import Pubnub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import { useEffect, useState } from 'react'

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context, location }) => {
    const { isAuthenticated, loginWithRedirect, user, isLoading } = context
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { returnTo: location.href } })
    }

    return { user }
  },
  component: AuthenticatedLayout,
})

function AuthenticatedLayout() {
  const { user } = useRouteContext({
    from: '/_authenticated',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [pubnub, setPubnub] = useState<Pubnub | null>(null)

  useEffect(() => {
    if (user) {
      const pubnub = new Pubnub({
        publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
        subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY!,
        uuid: user.sub || 'myUniqueUserId',
      })
      setPubnub(pubnub)
      setIsLoading(false)
    }
  }, [user])

  if (isLoading) return <div>Loading...</div>

  return pubnub ? (
    <PubNubProvider client={pubnub}>
      <Outlet />
    </PubNubProvider>
  ) : null
}
