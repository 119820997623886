import type { Organization } from '@/types.ts'

import api from './api.ts'

export async function getOrganizations() {
  const response = await api.get(`/v1/core/organizations/`)
  return response.data as Organization[]
}

export async function getOrganizationById(organizationId: string) {
  const response = await api.get(`/v1/core/organizations/${organizationId}`)
  return response.data as Organization
}
