import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import { useEffect } from 'react'

import { getUserInfo } from '@/api/user.ts'
import { OrganizationProvider } from '@/contexts/OrganizationContext.tsx'

import { initializeApi, refreshLogin } from './api/api.ts'
import { AUTH0_AUDIENCE } from './constants.ts'
import router from './router.ts'

export default function App() {
  const auth = useAuth0()
  const { data: userData } = useQuery({
    queryKey: ['userData'],
    queryFn: getUserInfo,
    enabled: auth.isAuthenticated,
  })
  const organizations =
    userData?.user.organization_members.map(
      (member: any) => member.organization
    ) || []

  useEffect(() => {
    initializeApi(() =>
      auth.getAccessTokenSilently({
        authorizationParams: {
          audience: AUTH0_AUDIENCE,
          scope: 'read:current_user',
        },
      })
    )
    refreshLogin(() =>
      auth.loginWithRedirect({ appState: { returnTo: window.location.href } })
    )
  }, [auth])

  return (
    <OrganizationProvider organizations={organizations}>
      <RouterProvider router={router} context={auth} />
    </OrganizationProvider>
  )
}
