import { useAuth0 } from '@auth0/auth0-react'
import { grey } from '@mui/material/colors'
import { useState, type MouseEvent } from 'react'

import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import { CircleUserRound, LogOut, Settings2 } from 'lucide-react'
import Avatar from '@mui/material/Avatar'

type UserMenuProps = {
  expanded: boolean
}
export default function UserMenu({ expanded }: UserMenuProps) {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { user } = useAuth0()

  return (
    <>
      <ListItem
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          ':hover': {
            bgcolor: grey[700],
          },
        }}
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: expanded ? 2 : 'auto',
            justifyContent: 'center',
            color: 'white',
          }}
        >
          {user && user.picture ? (
            <Avatar src={user.picture} alt={user.name} />
          ) : (
            <CircleUserRound />
          )}
        </ListItemIcon>
        {expanded && <ListItemText primary="My Account" />}
      </ListItem>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 0, vertical: -135 }}
        slotProps={{ paper: { sx: { width: 280 } } }}
        TransitionComponent={Fade}
      >
        <Typography px={2} fontWeight="bold" mb={1}>
          {user && user.name ? user.name : 'My Account'}
        </Typography>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings2 size={16} />
          </ListItemIcon>
          <ListItemText primary="Account Preferences" />
        </MenuItem>
        <Divider />
        <MenuItem
          component="div"
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <ListItemIcon>
            <LogOut size={16} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  )
}
