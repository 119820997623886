import api from './api.ts'
import { getUserInfo } from './user.ts'
import type { GCSFile, PlantList } from '../types.ts'

export async function getPlantLists(
  limit: number,
  page: number,
  orderBy: string,
  orderByDirection: 'asc' | 'desc',
  filters?: {
    listNameFilter?: string | undefined
    rfpFilter?: string | undefined
    dateCreatedRangeFrom?: Date | undefined
    dateCreatedRangeTo?: Date | undefined
    emailFilter?: string | undefined
  }
) {
  const nameFilterString = filters?.listNameFilter
    ? `&name=${filters.listNameFilter}`
    : ''
  const emailFilterString = filters?.emailFilter
    ? `&email=${filters.emailFilter}`
    : ''
  const rfpFilterString = filters?.rfpFilter
    ? `&rfp_id=${filters.rfpFilter}`
    : ''
  const dateCreatedRangeFromString = filters?.dateCreatedRangeFrom
    ? `&greater_than_or_equal_to_created_at=${filters.dateCreatedRangeFrom.toISOString()}`
    : ''
  const dateCreatedRangeToString = filters?.dateCreatedRangeTo
    ? `&less_than_or_equal_to_created_at=${filters.dateCreatedRangeTo.toISOString()}`
    : ''
  const url = `/v1/mdb/plant_list/?limit=${limit}&page=${page}&order_by=${orderBy}&order_by_direction=${orderByDirection}${nameFilterString}${emailFilterString}${rfpFilterString}${dateCreatedRangeFromString}${dateCreatedRangeToString}`
  const response = await api.get(url)
  return response.data as PlantList[]
}

export async function getPlantListById(id: string) {
  const url = `/v1/mdb/plant_list/${id}`

  const response = await api.get(url)
  return response.data as PlantList
}

export async function createPlantList(formData: FormData) {
  const userInfo = await getUserInfo()
  if (!userInfo) {
    throw new Error('Cannot create a plant list without user information.')
  }
  const organization_members = userInfo.user.organization_members
  if (
    !organization_members ||
    !organization_members.length ||
    !organization_members[0].organization
  ) {
    throw new Error(
      'No organization info found for user. A user that does not belong to an organization cannot create a plant list.'
    )
  }

  const plantList = {
    name: formData.get('name') as string,
    files: formData.getAll('files') as File[],
  }

  const url = '/v1/mdb/plant_list/'
  const files = plantList.files.map((file) => ({
    user_file_name: file.name,
    encoding: file.type,
    domain: 'plant_list',
    file_type: file.type,
  }))
  const body = {
    name: plantList.name,
    files,
    organization: organization_members[0].organization.id,
  }

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as PlantList
}

export async function updatePlantList(plantList: PlantList) {
  const body = {
    name: plantList.name,
    files: plantList.files,
    entries: plantList.entries,
    organization: plantList.organization.id,
  }
  const url = `/v1/mdb/plant_list/${plantList.id}`

  const response = await api.put(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as PlantList
}

export async function addFileToPlantList(plantListId: string, file: GCSFile) {
  const url = `/v1/mdb/plant_list/${plantListId}`
  const plantListResponse = await api.get(url)
  const plantList = plantListResponse.data as PlantList
  const plantListFiles = plantList.files.concat(file)
  const body = {
    name: plantList.name,
    files: plantListFiles,
    entries: plantList.entries,
    organization: plantList.organization.id,
  }
  const response = await api.put(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as PlantList
}

interface EnumResponse {
  error?: any
  endpoint?: string
  config?: {
    url: string
  }
  data: any
  cached: boolean
}
export async function getOptions() {
  const enumEndpoints = [
    'imperialvolumemeasurement',
    'imperialareameasurement',
    'imperiallengthmeasurement',
    'trunkplurality',
    'shape',
    'plantstage',
    'palmtrunk',
    'characteristics',
    'shippingcontainer',
    'plantcontainer',
    'rootpackaging',
  ]
  const enumRequests = enumEndpoints.map(
    (endpoint) =>
      api.get(`/v1/mdb/plant_list/enum/${endpoint}`).catch((error) => {
        return { error, endpoint, data: [] }
      }) as Promise<EnumResponse>
  )

  const enumResponses = await Promise.all(enumRequests)
  return enumResponses.reduce(
    (acc: Record<string, string[]>, response: EnumResponse) => {
      if (response.error) {
        console.error(
          `Error fetching enum: ${response.endpoint}`,
          response.error
        )
        acc[response.endpoint as string] = []
      } else {
        const enumName = response.config?.url.split('/').pop() as string
        acc[enumName] = response.data
      }
      return acc
    },
    {}
  )
}
