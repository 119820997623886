import { useQuery } from '@tanstack/react-query'
import { type SyntheticEvent, useState } from 'react'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

import a11yProps from '@/components/ui/a11y-props.ts'
import TabPanel from '@/components/ui/tab-panel.tsx'
import OrganizationDetailsForm from '@/components/ui/forms/organization-details-form.tsx'

import { getOrganizationById } from '@/api/organization_management.ts'
import { useOrganization } from '@/contexts/OrganizationContext.tsx'

export default function SettingsAndMembersTabs() {
  const { selectedOrganization } = useOrganization()
  const [value, setValue] = useState(0)
  const { data: organization } = useQuery({
    queryKey: ['organization', selectedOrganization?.id],
    queryFn: () => {
      if (selectedOrganization) {
        return getOrganizationById(selectedOrganization.id)
      }
    },
  })
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="organization settings tabs"
      >
        <Tab label="Organization" {...a11yProps(0, 'org-settings')} />
        <Tab label="Locations" {...a11yProps(1, 'org-settings')} />
        <Tab label="Team Members" {...a11yProps(2, 'org-settings')} />
        {/*<Tab label="Delivery Vehicles" {...a11yProps(3, 'org-settings')} />*/}
        <Tab label="Data Sharing" {...a11yProps(4, 'org-settings')} />
      </Tabs>
      <TabPanel id="org-settings" index={0} value={value}>
        <Box>
          <Typography variant="h3" gutterBottom>
            Organization Details
          </Typography>
          <OrganizationDetailsForm organization={organization} />
        </Box>
      </TabPanel>
      <TabPanel id="org-settings" index={1} value={value}>
        <Box>
          <Typography variant="h3" gutterBottom>
            Locations
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel id="org-settings" index={2} value={value}>
        <Box>
          <Typography variant="h3" gutterBottom>
            Team Members
          </Typography>
        </Box>
      </TabPanel>
      {/*<TabPanel id="org-settings" index={3} value={value}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="h3" gutterBottom>*/}
      {/*      Delivery Vehicles*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</TabPanel>*/}
      <TabPanel id="org-settings" index={4} value={value}>
        <Box>
          <Typography variant="h3" gutterBottom>
            Data Sharing
          </Typography>
        </Box>
      </TabPanel>
    </Box>
  )
}
