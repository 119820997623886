import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'

import InlineEditableRangeDimension from './inline-editable-range-dimension.tsx'
import InlineEditableMeasurableRangeDimension from './inline-editable-measurable-range-dimension.tsx'
import InlineEditableSelect from './inline-editable-select.tsx'
import {
  type MeasurableRangeDimension,
  MultiTrunk,
  type PlantListEntry,
  type RangeDimension,
  type SingleTrunk,
  TrunkPlurality,
} from '../../types.ts'

const EditableChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  // height: 32,
  margin: theme.spacing(0.5),
  padding: theme.spacing(1, 1),
  fontSize: '0.8125rem',
  backgroundColor: theme.palette.grey[300],
  borderRadius: 16,
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}))

// Helper function to check if a value should be included
const shouldIncludeValue = (value: any): boolean => {
  if (value === null || value === undefined) {
    return false
  }
  if (Array.isArray(value)) {
    return value.length > 0
  }
  if (typeof value === 'object' && ('min' in value || 'max' in value)) {
    return value.min !== null || value.max !== null
  }
  return true
}

type PlantItemSpecsProps = {
  entry: PlantListEntry
  onUpdate: (updatedPlant: PlantListEntry) => void
  optionsEnums: Record<string, any> | undefined
}
export default function PlantItemSpecs({
  entry,
  onUpdate,
  optionsEnums,
}: PlantItemSpecsProps) {
  const handleTrunkPluralityChange = (value: string) => {
    if (value === TrunkPlurality.SingleTrunk) {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.SingleTrunk,
          trunk_count: { min: 1, max: 1 },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as SingleTrunk,
      })
    } else {
      onUpdate({
        ...entry,
        trunk_form: {
          plurality: TrunkPlurality.MultiTrunk,
          trunk_count: { min: 1, max: 1 },
          cane_caliper: { min: 1, max: 1, unit: null },
          clear_trunk_size: { min: 1, max: 1, unit: null },
        } as MultiTrunk,
      })
    }
  }

  const handleTrunkCountChange = (value: RangeDimension) => {
    if (
      !entry.trunk_form ||
      entry.trunk_form.plurality === TrunkPlurality.SingleTrunk
    ) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        trunk_count: value,
      },
    })
  }

  const handleCaneCaliperChange = (value: MeasurableRangeDimension) => {
    if (
      !entry.trunk_form ||
      entry.trunk_form.plurality === TrunkPlurality.SingleTrunk
    ) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        cane_caliper: value,
      },
    })
  }

  const handleClearTrunkSizeChange = (value: MeasurableRangeDimension) => {
    if (!entry.trunk_form) {
      return
    }
    onUpdate({
      ...entry,
      trunk_form: {
        ...entry.trunk_form,
        clear_trunk_size: value,
      },
    })
  }
  const handleSpecUpdate = (field: string, value: any) => {
    if (field === 'trunk_form.plurality') {
      handleTrunkPluralityChange(value as string)
    } else if (field === 'trunk_form.clear_trunk_size') {
      handleClearTrunkSizeChange(value as MeasurableRangeDimension)
    } else if (field === 'trunk_form.trunk_count') {
      handleTrunkCountChange(value as RangeDimension)
    } else if (field === 'trunk_form.cane_caliper') {
      handleCaneCaliperChange(value as MeasurableRangeDimension)
    } else {
      onUpdate({ ...entry, [field]: value })
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const specs = [
    {
      label: 'Area',
      value: entry.surface_area,
      field: 'surface_area',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperialareameasurement,
    },
    {
      label: 'Cont. Size',
      value: entry.container,
      field: 'container',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperialvolumemeasurement,
    },
    {
      label: 'Cal',
      value: entry.caliper,
      field: 'caliper',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'H',
      value: entry.height,
      field: 'height',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'W',
      value: entry.width,
      field: 'width',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'DBH',
      value: entry.dbh,
      field: 'dbh',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: '',
      value: entry.trunk_form?.plurality,
      field: 'trunk_form.plurality',
      type: 'select',
      options: optionsEnums?.trunkplurality,
    },
    {
      label: 'Clr. Trunk',
      value: entry.trunk_form?.clear_trunk_size,
      field: 'trunk_form.clear_trunk_size',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'Trunk Cnt.',
      value: entry.trunk_form?.trunk_count,
      field: 'trunk_form.trunk_count',
      type: 'range',
      show: entry.trunk_form?.plurality !== TrunkPlurality.SingleTrunk,
    },
    {
      label: 'Cane Cal',
      value: (entry.trunk_form as MultiTrunk)?.cane_caliper,
      field: 'trunk_form.cane_caliper',
      type: 'measurableRange',
      unitOptions: optionsEnums?.imperiallengthmeasurement,
    },
    {
      label: 'Stage',
      value: entry.plant_stage,
      field: 'plant_stage',
      type: 'multiSelect',
      options: optionsEnums?.plantstage,
    },
    {
      label: 'Palm Trunk',
      value: entry.palm_trunk,
      field: 'palm_trunk',
      type: 'multiSelect',
      options: optionsEnums?.palmtrunk,
    },
    {
      label: 'Chars.',
      value: entry.characteristics,
      field: 'characteristics',
      type: 'multiSelect',
      options: optionsEnums?.characteristics,
    },
    {
      label: 'Shape',
      value: entry.shape,
      field: 'shape',
      type: 'multiSelect',
      options: optionsEnums?.shape,
    },
    {
      label: 'Pkg. Cnt.',
      value: entry.package_count,
      field: 'package_count',
      type: 'range',
    },
    {
      label: 'Shipping',
      value: entry.shipping_container,
      field: 'shipping_container',
      type: 'select',
      options: optionsEnums?.shippingcontainer,
    },
    {
      label: 'Plant Cont.',
      value: entry.plant_container,
      field: 'plant_container',
      type: 'select',
      options: optionsEnums?.plantcontainer,
    },
    {
      label: 'Root Pkg.',
      value: entry.root_packaging,
      field: 'root_packaging',
      type: 'select',
      options: optionsEnums?.rootpackaging,
    },
  ].filter(
    (spec) =>
      shouldIncludeValue(spec.value) && (spec.show === undefined || spec.show)
  )

  const renderEditableField = (spec: any) => {
    switch (spec.type) {
      case 'range':
        return (
          <InlineEditableRangeDimension
            label={spec.label}
            value={spec.value}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            onClick={handleClick}
          />
        )
      case 'measurableRange':
        return (
          <InlineEditableMeasurableRangeDimension
            label={spec.label}
            value={spec.value}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            unitOptions={spec.unitOptions}
            onClick={handleClick}
          />
        )
      case 'select':
        return (
          <InlineEditableSelect
            label={spec.label}
            value={spec.value}
            options={spec.options}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            onClick={handleClick}
          />
        )
      case 'multiSelect':
        return (
          <InlineEditableSelect
            label={spec.label}
            value={spec.value}
            options={spec.options}
            onSave={(newValue) => handleSpecUpdate(spec.field, newValue)}
            multiple
            onClick={handleClick}
          />
        )
      default:
        return null
    }
  }

  return (
    <Box>
      {specs.map((spec, index) => (
        <EditableChip key={index}>{renderEditableField(spec)}</EditableChip>
      ))}
    </Box>
  )
}
