import { z } from 'zod'
import validator from 'validator'

import {
  ACCEPTED_FILE_TYPES,
  MAX_IMAGE_DIMENSION,
  US_STATES,
} from '../constants'
import { isImageFile, checkImageDimensions } from './utils.ts'

export const CreatePlantListFormSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  files: z
    .any()
    .refine(
      (files: FileList) => files && files.length > 0,
      'Provide at least one file'
    )
    .refine((files: FileList) => {
      const filesArray = Array.from(files)
      return filesArray.every((file: File) =>
        ACCEPTED_FILE_TYPES.includes(file.type)
      )
    }, 'Only files of the following format are allowed: .jpeg, .jpg, .png, .gif, .webp, .csv, .xlsx, and .pdf.')
    .refine(async (files: FileList) => {
      const filesArray = Array.from(files)
      const imageDimensionChecks = await Promise.all(
        filesArray.map(async (file: File) => {
          if (isImageFile(file)) {
            return await checkImageDimensions(file)
          }
          return true
        })
      )
      return imageDimensionChecks.every(Boolean)
    }, `Image files must not exceed ${MAX_IMAGE_DIMENSION} pixels in width or height.`),
})

export type PlantListForm = z.infer<typeof CreatePlantListFormSchema>

export const FilterPlantListsFormSchema = z.object({
  listNameFilter: z.string().optional(),
  rfpFilter: z.string().optional(),
  dateCreatedRangeFrom: z.date().optional(),
  dateCreatedRangeTo: z.date().optional(),
  emailFilter: z.string().optional(),
})

/**
 * Schema for contact details
 */
const zipRegex = /^\d{5}(-\d{4})?$/

const addressSchema = z
  .object({
    street1: z.string(),
    street2: z.string(),
    city: z.string(),
    state: z.enum(US_STATES).or(z.literal('')),
    zip: z.string().regex(zipRegex, 'Invalid zip code').or(z.literal('')),
  })
  .optional()

const phoneSchema = z.object({
  number: z
    .string()
    .refine(validator.isMobilePhone, { message: 'Invalid phone number' }),
  contact_type: z.enum(['work', 'mobile', 'other']).optional(),
})

const emailSchema = z.object({
  address: z.string().email('Invalid email address'),
  contact_type: z.enum(['work', 'other']).optional(),
})

const contactMethodSchema = z.object({
  phone: z.array(phoneSchema).optional(),
  email: z.array(emailSchema).optional(),
})

export const OrganizationContactSchema = contactMethodSchema.extend({
  type: z.literal('organization'),
  organization_name: z.string().min(1, 'Organization name is required'),
  address: addressSchema,
})

export const PersonContactSchema = contactMethodSchema
  .extend({
    type: z.literal('user'),
    first_name: z.string().min(1, 'First name is required'),
    last_name: z.string().min(1, 'Last name is required'),
  })
  .refine(
    (contact) =>
      (contact.phone && contact.phone.length > 0) ||
      (contact.email && contact.email.length > 0),
    {
      message: 'At least one phone number or email address is required',
      path: ['contactMethod'],
    }
  )
