import { type ReactNode } from 'react'
import Box from '@mui/material/Box'

type TabPanelProps = {
  children?: ReactNode
  id: string
  index: number
  value: number
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, id, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
