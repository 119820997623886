import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'

import CheckIcon from '@mui/icons-material/Check'

type InlineEditableSelectProps = {
  label: string
  value: string | string[]
  options: string[]
  onSave: (newValue: string | string[]) => void
  multiple?: boolean
  onClick: (event: React.MouseEvent) => void
}

const InlineEditableSelect: React.FC<InlineEditableSelectProps> = ({
  label,
  value,
  options,
  onSave,
  multiple = false,
  onClick,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)

  const handleSave = () => {
    setIsEditing(false)
    onSave(editedValue)
  }

  if (isEditing) {
    return (
      <Box display="flex" alignItems="center" onClick={onClick}>
        <Select
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
          multiple={multiple}
          renderValue={
            multiple
              ? (selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as string[]).map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )
              : undefined
          }
          size="small"
          style={{ minWidth: 120, backgroundColor: 'white' }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Button
          onClick={handleSave}
          startIcon={<CheckIcon color="inherit" />}
          color="inherit"
        />
      </Box>
    )
  }

  return (
    <div onClick={onClick}>
      <Typography
        onClick={() => setIsEditing(true)}
        style={{ cursor: 'pointer' }}
      >
        {label && (
          <Typography variant="body2" component="span">
            {label}:{' '}
          </Typography>
        )}
        {multiple ? (value as string[]).join(', ') : value}
      </Typography>
    </div>
  )
}

export default InlineEditableSelect
