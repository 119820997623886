import React, { useState, useRef, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

type InlineEditableTextFieldProps = {
  value: string | null
  onSave: (newValue: string | null) => void
  onClick: (event: React.MouseEvent) => void
  testId?: string
}

const InlineEditableTextField: React.FC<InlineEditableTextFieldProps> = ({
  value,
  onSave,
  onClick,
  testId,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const handleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
    if (editedValue !== value) {
      onSave(editedValue)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleBlur()
    } else if (e.key === 'Escape') {
      setIsEditing(false)
      setEditedValue(value)
    }
  }

  if (isEditing) {
    return (
      <TextField
        ref={inputRef}
        value={editedValue}
        onChange={(e) => setEditedValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        type="text"
        size="small"
        fullWidth
        onClick={onClick}
        sx={{ minWidth: 200 }}
        inputProps={{ 'data-testid': `${testId}-input` }}
      />
    )
  }

  return (
    <div onClick={onClick}>
      <Typography
        variant="body2"
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
        data-testid={testId}
      >
        {value}
      </Typography>
    </div>
  )
}

export default InlineEditableTextField
