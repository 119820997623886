import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import PlantItemSpecs from './plant-item-specs.tsx'
import InlineEditableRangeDimension from './inline-editable-range-dimension.tsx'
import InlineEditableTextField from './inline-editable-text-field.tsx'

import type { PlantListEntry, RangeDimension } from '@/types.ts'

type SortablePlantItemProps = {
  plant: PlantListEntry
  isSelected: boolean
  onSelect: () => void
  onClick: (id: string) => void
  onUpdate: (updatedPlant: PlantListEntry) => void
  optionsEnums: Record<string, any> | undefined
  testId?: string
}
export default function SortablePlantItem({
  plant,
  isSelected,
  onSelect,
  onClick,
  onUpdate,
  optionsEnums,
  testId,
}: SortablePlantItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: plant.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  const handleCheckboxClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    onSelect()
  }

  const handlePropClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const handleQuantityUpdate = (newValue: RangeDimension) => {
    onUpdate({ ...plant, quantity_count: newValue })
  }

  const handleCommonNameUpdate = (newValue: string | null) => {
    onUpdate({ ...plant, common_name: newValue })
  }

  const handleScientificNameUpdate = (newValue: string | null) => {
    onUpdate({ ...plant, scientific_name: newValue })
  }

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      hover
      onClick={() => onClick(plant.id)}
    >
      <TableCell padding="none" style={{ width: '48px' }}>
        <div
          {...attributes}
          {...listeners}
          className="flex h-full cursor-move items-center justify-center"
        >
          <DragIndicatorIcon />
        </div>
      </TableCell>
      <TableCell padding="checkbox" onClick={handleCheckboxClick}>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell align="center">
        <InlineEditableRangeDimension
          value={plant.quantity_count}
          onSave={handleQuantityUpdate}
          onClick={handlePropClick}
          truncateRange={true}
        />
      </TableCell>
      <TableCell align="center">
        <InlineEditableTextField
          value={plant.common_name}
          onClick={handlePropClick}
          onSave={handleCommonNameUpdate}
          testId={`${testId}-common-name`}
        />
      </TableCell>
      <TableCell align="center">
        <InlineEditableTextField
          value={plant.scientific_name}
          onClick={handlePropClick}
          onSave={handleScientificNameUpdate}
        />
      </TableCell>
      <TableCell align="center">
        <PlantItemSpecs
          entry={plant}
          onUpdate={onUpdate}
          optionsEnums={optionsEnums}
        />
      </TableCell>
    </TableRow>
  )
}
