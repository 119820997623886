import api from './api.ts'
import type { AIProcessingData } from '../types.ts'

export async function processFileWithAI({
  fileId,
  plantListId,
}: {
  fileId: string
  plantListId: string
}) {
  const url = '/v1/mdb/ai_processed_plant_list_file/'
  const body = JSON.stringify({
    uploaded_file: fileId,
    parent_plant_list: plantListId,
  })

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export async function getFileProcessingForPlantLists(
  parent_plant_list_ids: string[]
) {
  const parent_plant_list_queries = parent_plant_list_ids
    .map((id) => `parent_plant_list=${id}`)
    .join('&')

  const response = await api.get(
    `/v1/mdb/ai_processed_plant_list_file/?limit=0&${parent_plant_list_queries}`
  )
  return response.data as AIProcessingData[]
}
