import { useState, type MouseEvent, useEffect } from 'react'
import { grey } from '@mui/material/colors'

import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

import {
  Building2,
  Check,
  ChevronDown,
  ChevronUp,
  Plus,
  Settings,
} from 'lucide-react'

import type { Organization } from '@/types.ts'
import { useOrganization } from '@/contexts/OrganizationContext.tsx'
import SettingsAndMembersModal from '@/components/ui/modals/settings-and-members-modal.tsx'

type OrganizationMenuProps = {
  expanded: boolean
}
export default function OrganizationMenu({ expanded }: OrganizationMenuProps) {
  const { selectedOrganization, setSelectedOrganization, organizations } =
    useOrganization()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [orgSettingsOpen, setOrgSettingsOpen] = useState(false)

  useEffect(() => {
    setSelectedOrganization(organizations?.[0] || null)
  }, [organizations, setSelectedOrganization])

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOrganizationSelect = (organization: Organization) => {
    setSelectedOrganization(organization)
    handleClose()
  }

  const handleOrgSettingsOpen = () => {
    setOrgSettingsOpen(true)
    handleClose()
  }

  return (
    <>
      <ListItem
        onClick={handleClick}
        sx={{
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          ':hover': {
            bgcolor: grey[700],
          },
        }}
        aria-controls={open ? 'organization-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {!expanded && (
          <ListItemIcon sx={{ minWidth: 0 }}>
            <Avatar>{selectedOrganization?.name[0] || <Building2 />}</Avatar>
          </ListItemIcon>
        )}
        {expanded && (
          <>
            <ListItemText
              primary={
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                  {selectedOrganization?.name || 'Select Organization'}
                </Typography>
              }
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
            />
            <ListItemIcon
              sx={{
                minWidth: 0,
                color: 'white',
              }}
            >
              {open ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
            </ListItemIcon>
          </>
        )}
      </ListItem>
      <Menu
        id="organization-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        slotProps={{ paper: { sx: { width: 280 } } }}
        TransitionComponent={Fade}
      >
        <Typography px={2} fontWeight="bold" mb={1}>
          My Organizations
        </Typography>
        {organizations && organizations.length > 0 ? (
          organizations.map((organization) => (
            <MenuItem
              key={organization.id}
              onClick={() => handleOrganizationSelect(organization)}
            >
              <ListItemText
                primary={organization.name}
                sx={{ whiteSpace: 'wrap' }}
              />
              {selectedOrganization?.id === organization.id && (
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                  <Check size={16} />
                </ListItemIcon>
              )}
            </MenuItem>
          ))
        ) : (
          <Typography p={2} pt={0} textAlign="center">
            You don&apos;t belong to any organizations.
          </Typography>
        )}
        <Divider />
        <MenuItem onClick={handleOrgSettingsOpen}>
          <ListItemIcon>
            <Settings size={16} />
          </ListItemIcon>
          <ListItemText primary="Settings & Members" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Plus size={16} />
          </ListItemIcon>
          <ListItemText
            primary={`Add ${organizations && organizations.length > 0 ? 'another' : ''} organization ...`}
          />
        </MenuItem>
      </Menu>

      <SettingsAndMembersModal
        open={orgSettingsOpen}
        onClose={() => setOrgSettingsOpen(false)}
      />
    </>
  )
}
