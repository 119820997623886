import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import CheckIcon from '@mui/icons-material/Check'

import { formatDimension } from '../../lib/utils.ts'
import { type MeasurableRangeDimension } from '../../types'

type InlineEditableMeasurableRangeDimensionProps = {
  label: string
  value: MeasurableRangeDimension
  onSave: (newValue: MeasurableRangeDimension) => void
  unitOptions: string[]
  onClick: (event: React.MouseEvent) => void
}

const InlineEditableMeasurableRangeDimension: React.FC<
  InlineEditableMeasurableRangeDimensionProps
> = ({ label, value, onSave, unitOptions, onClick }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)

  const handleSave = () => {
    setIsEditing(false)
    onSave(editedValue)
  }

  if (isEditing) {
    return (
      <Box display="flex" alignItems="center" onClick={onClick}>
        <TextField
          value={editedValue.min}
          onChange={(e) =>
            setEditedValue({ ...editedValue, min: Number(e.target.value) })
          }
          type="number"
          size="small"
          style={{ width: 60, marginRight: 8, backgroundColor: 'white' }}
        />
        <Typography>-</Typography>
        <TextField
          value={editedValue.max}
          onChange={(e) =>
            setEditedValue({ ...editedValue, max: Number(e.target.value) })
          }
          type="number"
          size="small"
          style={{
            width: 60,
            marginLeft: 8,
            marginRight: 8,
            backgroundColor: 'white',
          }}
        />
        <Select
          value={editedValue.unit}
          onChange={(e) =>
            setEditedValue({ ...editedValue, unit: e.target.value })
          }
          size="small"
          style={{ width: 80, backgroundColor: 'white' }}
        >
          {unitOptions.map((unit) => (
            <MenuItem key={unit} value={unit}>
              {unit}
            </MenuItem>
          ))}
        </Select>
        <Button
          onClick={handleSave}
          startIcon={<CheckIcon color="inherit" />}
          color="inherit"
        />
      </Box>
    )
  }

  return (
    <div onClick={onClick}>
      <Typography
        onClick={() => setIsEditing(true)}
        style={{ cursor: 'pointer' }}
      >
        {label && (
          <Typography variant="body2" component="span">
            {label}:{' '}
          </Typography>
        )}
        {formatDimension(value)}
      </Typography>
    </div>
  )
}

export default InlineEditableMeasurableRangeDimension
