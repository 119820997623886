import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import { MeasurableRangeDimension } from '../../types.ts'

type MeasurableRangeDimensionInputProps = {
  dimension: MeasurableRangeDimension
  label: string
  unitOptions: string[]
  onChange: (dimension: MeasurableRangeDimension) => void
}

export default function MeasurableRangeDimensionInput({
  dimension,
  label,
  unitOptions,
  onChange,
}: MeasurableRangeDimensionInputProps) {
  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Box display="flex" gap={2} marginTop={1}>
        <TextField
          label="Min"
          type="number"
          value={dimension.min ?? ''}
          onChange={(e) =>
            onChange({
              ...dimension,
              min: e.target.value ? Number(e.target.value) : null,
            })
          }
          sx={{ maxWidth: 85 }}
        />
        <TextField
          label="Max"
          type="number"
          value={dimension.max ?? ''}
          onChange={(e) =>
            onChange({
              ...dimension,
              max: e.target.value ? Number(e.target.value) : null,
            })
          }
          sx={{ maxWidth: 85 }}
        />
        <Box sx={{ minWidth: 130 }}>
          <FormControl fullWidth>
            <InputLabel id={`${label} Unit`}>{`Unit`}</InputLabel>
            <Select
              labelId={`${label} Unit`}
              label={`Unit`}
              onChange={(e) =>
                onChange({ ...dimension, unit: e.target.value as string })
              }
              value={dimension.unit || ''}
            >
              {unitOptions.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}
