import { ContactCategories, ContactTypes } from '@/constants.ts'
import type {
  Contact,
  ContactCountCollection,
  ContactDetails,
  ContactDetailsResponse,
  ContactType,
  ElasticsearchResponse,
  Organization,
  OrganizationContact,
} from '@/types.ts'

import api from './api.ts'

export async function getContacts(
  contactType: ContactTypes,
  category: ContactCategories,
  owner: Organization | null
) {
  let categoryFilter = ''
  if (!owner) {
    throw new Error('Cannot get contacts without an owner organization')
  }
  if (category !== 'all') {
    categoryFilter = `&${category}=true`
  }
  const response = await api.get(
    `/v1/contact/search/?owner=${owner.id}&type=${contactType}${categoryFilter}`
  )
  return response.data as ElasticsearchResponse<Contact>
}

export async function searchContacts(
  contactType: ContactTypes,
  category: ContactCategories,
  keyword: string,
  owner: Organization | null
) {
  let categoryFilter = ''
  if (!owner) {
    throw new Error('Cannot search contacts without an owner organization')
  }
  if (category !== 'all') {
    categoryFilter = `&${category}=true`
  }
  const response = await api.get(
    `/v1/contact/search/?owner=${owner.id}&type=${contactType}&keyword=${keyword}${categoryFilter}`
  )
  return response.data as ElasticsearchResponse<Contact>
}

export async function searchOrganizationContacts(
  organizationName: string,
  owner: Organization | null
) {
  // wait 5 seconds to test loading states
  // await new Promise((resolve) => setTimeout(resolve, 5000))
  if (!owner) {
    throw new Error('Cannot search contacts without an owner organization')
  }
  const response = await api.get(
    `/v1/contact/search/?owner=${owner.id}&type=organization&organization_name=${organizationName}`
  )
  return response.data as ElasticsearchResponse<OrganizationContact>
}

export async function getContactDetails(id: string) {
  const response = await api.get(`/v1/contact/details/${id}`)
  return response.data as ContactDetailsResponse
}

export async function getContactCounts() {
  const response = await api.get('/v1/contact/counts/')
  return response.data as ContactCountCollection[]
}

export async function createContact({
  contactDetails,
  owner,
}: {
  contactDetails: ContactDetails
  owner: Organization | null
}) {
  if (!owner) {
    throw new Error('Cannot create contact without an owner organization')
  }
  const body: any = contactDetails
  body.owner = owner

  if (
    contactDetails.address &&
    contactDetails.address.street1 &&
    contactDetails.address.city &&
    contactDetails.address.state &&
    contactDetails.address.zip
  ) {
    const street =
      contactDetails.address.street1 && contactDetails.address.street2
        ? `${contactDetails.address.street1} ${contactDetails.address.street2 || ''}`
        : contactDetails.address.street1
    body.address = [
      {
        address: {
          street,
          city: contactDetails.address.city,
          state: contactDetails.address.state,
          zip: contactDetails.address.zip,
          country: 'United States',
        },
      },
    ]
  } else {
    body.address = []
  }

  // filter out empty organization names from organization_contact
  if (contactDetails.organization_contact) {
    body.organization_contact = contactDetails.organization_contact.filter(
      (orgContact) => orgContact.organization_name.trim()
    )
  }

  const url =
    contactDetails.type === 'user'
      ? '/v1/contact/user/'
      : '/v1/contact/organization/'

  const response = await api.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data
}

export async function updateContact(contact: Contact) {
  const url =
    contact.type === ContactTypes.USER
      ? `/v1/contact/user/${contact.id}`
      : `/v1/contact/organization/${contact.id}`
  const response = await api.put(url, contact, {
    headers: { 'Content-Type': 'application/json' },
  })
  return response.data as ContactDetailsResponse
}

export async function updateContactById({
  id,
  contactDetails,
  owner,
}: {
  id: string
  contactDetails: ContactDetails
  owner: Organization | null
}) {
  if (!owner) {
    throw new Error('Cannot update contact without an owner organization')
  }
  const body: any = contactDetails
  body.owner = owner

  if (contactDetails.address) {
    const street = `${contactDetails.address.street1} ${contactDetails.address.street2 || ''}`
    body.address = [
      {
        address: {
          street,
          city: contactDetails.address.city,
          state: contactDetails.address.state,
          zip: contactDetails.address.zip,
          country: 'United States',
        },
      },
    ]
  }

  const url =
    contactDetails.type === 'user'
      ? `/v1/contact/user/${id}`
      : `/v1/contact/organization/${id}`

  const response = await api.put(url, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as ContactDetailsResponse
}

export async function favoriteContact(
  contact: Contact,
  organization_contacts: OrganizationContact[] | undefined
) {
  const body: Contact = {
    ...contact,
    favorite: true,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function unfavoriteContact(
  contact: Contact,
  organization_contacts: OrganizationContact[] | undefined
) {
  const body: Contact = {
    ...contact,
    favorite: false,
    organization_contact: [...(organization_contacts || [])],
  }
  await updateContact(body)
}

export async function deleteContact(id: string, type: ContactType) {
  const url =
    type === ContactTypes.USER
      ? `/v1/contact/user/${id}`
      : `/v1/contact/organization/${id}`
  const response = await api.delete(url)
  return response.status
}
