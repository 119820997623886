import { Organization } from '@/types.ts'

type OrganizationDetailsFormProps = {
  organization: Organization | undefined
}
export default function OrganizationDetailsForm({
  organization,
}: OrganizationDetailsFormProps) {
  return (
    <div>
      <p>ID: {organization?.id}</p>
      <p>Name: {organization?.name}</p>
    </div>
  )
}
