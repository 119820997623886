import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import {
  type ChangeEvent,
  type SyntheticEvent,
  useState,
  useCallback,
} from 'react'

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import { searchOrganizationContacts } from '@/api/contacts.ts'
import { useOrganization } from '@/contexts/OrganizationContext.tsx'
import type { ContactDetails, Email, Phone } from '@/types.ts'

interface OrganizationOptionType {
  inputValue?: string
  organization_name: string
  id?: string
}

const filter = createFilterOptions<OrganizationOptionType>()

type ContactPersonFormProps = {
  contactDetails: ContactDetails
  onContactDetailsChange: (details: Partial<ContactDetails>) => void
}

export default function ContactPersonForm({
  contactDetails,
  onContactDetailsChange,
}: ContactPersonFormProps) {
  const { selectedOrganization } = useOrganization()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  const { data: searchResults = { results: [] }, isLoading } = useQuery({
    queryKey: ['organization_contacts', debouncedSearchTerm],
    queryFn: () =>
      searchOrganizationContacts(debouncedSearchTerm, selectedOrganization),
    enabled: debouncedSearchTerm.length >= 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
  })

  const handleSearchInputChange = (_: SyntheticEvent, value: string) => {
    setSearchTerm(value)
  }

  const handleOrganizationChange = useCallback(
    (
      index: number,
      _: SyntheticEvent,
      value: string | OrganizationOptionType | null
    ) => {
      const updateOrganizationContacts = [
        ...(contactDetails.organization_contact || [
          { organization_name: '', owner: selectedOrganization },
        ]),
      ]
      if (typeof value === 'string') {
        updateOrganizationContacts[index] = {
          organization_name: value,
          owner: selectedOrganization,
        }
      } else if (value && value.inputValue) {
        updateOrganizationContacts[index] = {
          organization_name: value.inputValue,
          owner: selectedOrganization,
        }
      } else if (value) {
        updateOrganizationContacts[index] = {
          organization_name: value.organization_name || '',
          id: value.id!,
          owner: selectedOrganization,
        }
      }
      onContactDetailsChange({
        ...contactDetails,
        organization_contact: updateOrganizationContacts,
      })
    },
    [contactDetails, selectedOrganization, onContactDetailsChange]
  )

  const handleInputChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number = 0
    ) => {
      const { name, value } = event.target

      const updateContactDetail = (
        field: 'phone' | 'email',
        updateType: boolean = false
      ) => {
        const currentDetails = contactDetails[field] || []
        const updatedDetails = [...currentDetails]
        const currentDetail = updatedDetails[index] || {}

        if (updateType) {
          updatedDetails[index] = {
            ...currentDetail,
            contact_type: value as 'work' | 'mobile' | 'other',
          } as Phone | Email
        } else {
          updatedDetails[index] =
            field === 'phone'
              ? ({ ...currentDetail, number: value } as Phone)
              : ({ ...currentDetail, address: value } as Email)
        }

        return {
          ...contactDetails,
          [field]: updatedDetails,
        }
      }

      const inputHandlers: Record<string, () => ContactDetails> = {
        phone_number: () => updateContactDetail('phone'),
        phone_type: () => updateContactDetail('phone', true),
        email_address: () => updateContactDetail('email'),
        email_type: () => updateContactDetail('email', true),
        default: () => ({ ...contactDetails, [name]: value }),
      }

      const handler = inputHandlers[name] || inputHandlers.default
      onContactDetailsChange(handler())
    },
    [contactDetails, onContactDetailsChange]
  )

  const addPhone = () => {
    onContactDetailsChange({
      ...contactDetails,
      phone: [...(contactDetails.phone || []), { number: '' }],
    })
  }

  const addEmail = () => {
    onContactDetailsChange({
      ...contactDetails,
      email: [...(contactDetails.email || []), { address: '' }],
    })
  }

  const addOrganization = () => {
    onContactDetailsChange({
      ...contactDetails,
      organization_contact: [
        ...(contactDetails.organization_contact || []),
        { organization_name: '', owner: selectedOrganization },
      ],
    })
  }

  const removeOrganization = (index: number) => {
    const updatedOrganizationContacts = (
      contactDetails.organization_contact || []
    ).filter((_, i) => i !== index)
    onContactDetailsChange({
      ...contactDetails,
      organization_contact: updatedOrganizationContacts,
    })
  }

  return (
    <Box>
      {(
        contactDetails.organization_contact || [
          { organization_name: '', owner: selectedOrganization },
        ]
      ).map((org, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{ mb: 2 }}
        >
          <Grid item xs={10}>
            <Autocomplete
              freeSolo
              loading={isLoading}
              onInputChange={handleSearchInputChange}
              onChange={(event, value) =>
                handleOrganizationChange(index, event, value)
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const { inputValue } = params
                const isExisting = options.some(
                  (option) => inputValue === option.organization_name
                )
                if (
                  inputValue !== '' &&
                  inputValue.trim().length >= 3 &&
                  !isExisting
                ) {
                  filtered.push({
                    inputValue,
                    organization_name: `Add new organization contact: "${inputValue}"`,
                  })
                }
                return filtered
              }}
              selectOnFocus
              handleHomeEndKeys
              value={org.organization_name || ''}
              options={searchResults.results as OrganizationOptionType[]}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option
                }
                if (option.inputValue) {
                  return option.inputValue
                }
                return option.organization_name
              }}
              renderOption={(props, option) => {
                const { ...optionProps } = props
                return (
                  <li key={option.id} {...optionProps}>
                    {option.organization_name}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="organization_name"
                  label={`Organization Name ${index + 1}`}
                  placeholder="Search organization name..."
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            {index > 0 && (
              <IconButton
                onClick={() => removeOrganization(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addOrganization}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Organization
        </Typography>
      </Button>

      <Typography variant="subtitle1" gutterBottom>
        Name
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="first_name"
            label="First Name"
            placeholder="First Name"
            fullWidth
            required
            value={contactDetails.first_name || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            fullWidth
            required
            value={contactDetails.last_name || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle1" gutterBottom>
        Contact Details{' '}
        <Typography variant="caption">phone or email required</Typography>
      </Typography>

      {(contactDetails.phone && contactDetails.phone.length > 0
        ? contactDetails.phone
        : [{ number: '' }]
      ).map((phone, index) => (
        <Grid container spacing={2} mb={1} key={index}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              name="phone_number"
              label="Phone"
              type="tel"
              placeholder="(---) --- ----"
              fullWidth
              value={phone.number}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              name="phone_type"
              label="Type"
              fullWidth
              select
              value={phone.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addPhone}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Phone
        </Typography>
      </Button>
      {(contactDetails.email && contactDetails.email.length > 0
        ? contactDetails.email
        : [{ address: '' }]
      ).map((email, index) => (
        <Grid container spacing={2} mb={1} key={index}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              name="email_address"
              label="Email"
              type="email"
              placeholder="info@acme.co"
              fullWidth
              value={email.address}
              onChange={(event) => handleInputChange(event, index)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              name="email_type"
              label="Type"
              fullWidth
              select
              value={email.contact_type || ''}
              onChange={(event) => handleInputChange(event, index)}
            >
              <MenuItem value="work">Work</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ mb: 2 }}
        onClick={addEmail}
      >
        <Typography variant="button" textTransform="capitalize">
          + Add Another Email
        </Typography>
      </Button>
    </Box>
  )
}
