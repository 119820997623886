import type { SxProps, Theme } from '@mui/material/styles'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import { ContactTypes } from '@/constants.ts'
import type { ContactType } from '@/types.ts'

type SidebarItemProps = {
  label: string
  selected: boolean
  onChangeCategory: () => void
  totalCount: number
  peopleCount?: number
  organizationCount?: number
  onChangeContactType?: (type: ContactTypes) => void
  contactType?: ContactType
  sx?: SxProps<Theme>
  displaySubcategories?: boolean
}
export default function ContactsSidebarItem({
  label,
  totalCount,
  peopleCount,
  organizationCount,
  onChangeCategory,
  onChangeContactType,
  selected,
  contactType,
  sx,
  displaySubcategories = true,
}: SidebarItemProps) {
  return (
    <>
      <ListItemButton sx={sx} onClick={onChangeCategory}>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            sx: { fontWeight: selected ? 'bold' : 'light' },
          }}
        />
        <Typography variant="body2">{totalCount}</Typography>
      </ListItemButton>
      {selected && displaySubcategories && onChangeContactType && (
        <>
          <ListItemButton
            onClick={() => onChangeContactType(ContactTypes.USER)}
          >
            <ListItemText
              primary="People"
              primaryTypographyProps={{
                sx: {
                  fontWeight:
                    contactType === ContactTypes.USER ? 'bold' : 'light',
                  pl: '10px',
                },
              }}
            />
            <Typography variant="body2">{peopleCount}</Typography>
          </ListItemButton>
          <ListItemButton
            onClick={() => onChangeContactType(ContactTypes.ORGANIZATION)}
          >
            <ListItemText
              primary="Organizations"
              primaryTypographyProps={{
                sx: {
                  fontWeight:
                    contactType === ContactTypes.ORGANIZATION
                      ? 'bold'
                      : 'light',
                  pl: '10px',
                },
              }}
            />
            <Typography variant="body2">{organizationCount}</Typography>
          </ListItemButton>
        </>
      )}
    </>
  )
}
