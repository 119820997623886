import { addDays, subMilliseconds } from 'date-fns'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { Clear as ClearIcon } from '@mui/icons-material'

import { FilterPlantListsFormSchema } from '../../lib/validation-schemas.ts'

type PlantListsTableFiltersProps = {
  applyFilters: (filters: z.infer<typeof FilterPlantListsFormSchema>) => void
  clearFilters: () => void
}

export default function PlantListsTableFilters({
  applyFilters,
  clearFilters,
}: PlantListsTableFiltersProps) {
  const { control, handleSubmit, reset } = useForm<
    z.infer<typeof FilterPlantListsFormSchema>
  >({
    resolver: zodResolver(FilterPlantListsFormSchema),
  })

  const onSubmit = (data: z.infer<typeof FilterPlantListsFormSchema>) => {
    if (data.dateCreatedRangeTo) {
      data.dateCreatedRangeTo = subMilliseconds(
        addDays(data.dateCreatedRangeTo, 1),
        1
      )
    }
    applyFilters(data)
  }

  const handleClearFiltersClick = () => {
    reset({
      listNameFilter: '',
      rfpFilter: '',
      dateCreatedRangeFrom: undefined,
      dateCreatedRangeTo: undefined,
      emailFilter: '',
    })
    clearFilters()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="flex-center">
          <Grid item xs={12} sm={2}>
            <Controller
              name="listNameFilter"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="List Name"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="emailFilter"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="User E-mail"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="rfpFilter"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="RFP ID"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="dateCreatedRangeFrom"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="From Date Created At"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="dateCreatedRangeTo"
              control={control}
              render={({ field }) => (
                <DatePicker
                  label="To Date Created At"
                  value={field.value}
                  onChange={(date) => field.onChange(date)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box>
              <Button type="submit" variant="contained" color="primary">
                Apply Filters
              </Button>
              <Tooltip title="Clear all filters">
                <IconButton onClick={handleClearFiltersClick} size="large">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  )
}
