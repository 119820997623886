import { styled } from '@mui/material/styles'
import { useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { basicSearchV1Plant, searchV1Plant } from '../../api/elasticsearch.ts'
import { createRFP } from '../../api/plantbid-internal.ts'
import { type PlantList, type PlantListEntry } from '../../types.ts'

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    border: '1px solid #dadde9',
  },
}))

type V1Result = {
  id: number
  display_name: string
  common_names: string[]
  scientific_name: string
  genus: string
  species: string
}

const BlurredModal = styled(Modal)`
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.15);
`

type CreateRFPModalProps = {
  plantList: PlantList
  selectedPlantIds: Set<string>
  onClose: () => void
  onRFPCreated: () => void
}
export default function CreateRFPModal({
  plantList,
  selectedPlantIds,
  onClose,
  onRFPCreated,
}: CreateRFPModalProps) {
  const selectedPlants = plantList.entries.filter((plant) =>
    selectedPlantIds.has(plant.id)
  )
  const orderedPlants = selectedPlants.map((plant, index) => {
    const newPlant: PlantListEntry = { ...plant }
    if (index === selectedPlants.length - 1) {
      newPlant.parent_of_order = null
    } else {
      newPlant.parent_of_order = selectedPlants[index + 1].id
    }
    return newPlant
  })

  const plantV1MatchQueries = useQueries({
    queries: orderedPlants.map((plant) => ({
      queryKey: ['plant-v1-match', plant.id] as const,
      queryFn: () =>
        searchV1Plant({
          scientific_name: plant.scientific_name,
          common_name: plant.common_name,
        }),
    })),
  })

  const createRFPMutation = useMutation({
    mutationFn: (data: {
      id: string
      name: string
      entries: PlantListEntry[]
    }) => createRFP(data.id, data.name, data.entries),
    onSuccess: () => {
      onClose()
      onRFPCreated()
    },
    onError: (error) => {
      console.error('Error creating RFP:', error)
    },
  })

  const handleCreateRFPClick = async () => {
    console.log('Create RFP clicked')
    console.log('selected plants:', orderedPlants)
    await createRFPMutation.mutateAsync({
      id: plantList.id,
      name: plantList.name,
      entries: orderedPlants,
    })
  }

  const handleV1PlantChange = (plantId: string, newValue: V1Result | null) => {
    const plant = orderedPlants.find((p) => p.id === plantId)!
    plant.plant_id = newValue?.id.toString() || null
  }

  return (
    <BlurredModal open onClose={onClose}>
      <Box className="absolute left-1/2 top-1/2 max-h-[70vh] w-2/3 translate-x-[-50%] translate-y-[-50%] rounded-md border bg-white p-8 shadow-lg">
        <Typography variant="h2">Create RFP: Plant Mapping</Typography>
        <Box className="my-4">
          <TableContainer
            component={Paper}
            className="max-h-[40vh] overflow-y-scroll"
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Common Name</TableCell>
                  <TableCell>Scientific Name</TableCell>
                  <TableCell>V1 Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedPlants.map((plant, index) => {
                  const query = plantV1MatchQueries[index]
                  const options: V1Result[] =
                    query.data?.results.map((result: any) => ({
                      id: result.v1_id,
                      display_name: result.display_name,
                      common_names: result.common_names,
                      scientific_name: result.scientific_name,
                      genus: result.genus,
                      species: result.species,
                    })) || []
                  return (
                    <TableRow key={plant.id}>
                      <TableCell>{plant.common_name}</TableCell>
                      <TableCell>{plant.scientific_name}</TableCell>
                      <TableCell>
                        <V1PlantAutocomplete
                          plantId={plant.id}
                          initialOptions={options}
                          isLoading={query.isFetching}
                          onChange={(newValue) =>
                            handleV1PlantChange(plant.id, newValue)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display="flex" gap={4} justifyContent="end">
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateRFPClick}
            startIcon={
              createRFPMutation.isPending && <CircularProgress size={16} />
            }
            disabled={createRFPMutation.isPending}
          >
            Create RFP
          </Button>
        </Box>
      </Box>
    </BlurredModal>
  )
}

type RenderOptionProps = React.HTMLAttributes<HTMLLIElement> & {
  key?: string
  'data-option-index'?: number
}
type V1PlantAutocompleteProps = {
  plantId: string
  initialOptions: V1Result[]
  isLoading: boolean
  onChange: (value: V1Result | null) => void
}
function V1PlantAutocomplete({
  plantId,
  initialOptions,
  isLoading,
  onChange,
}: V1PlantAutocompleteProps) {
  const [inputValue, setInputValue] = useState('')
  const { data: searchResults, isFetching: isSearching } = useQuery({
    queryKey: ['plant-v1-search', plantId, inputValue],
    queryFn: () => basicSearchV1Plant(inputValue),
    enabled: inputValue.length > 2,
  })
  const options: V1Result[] =
    inputValue.length > 2
      ? searchResults?.results.map((result: any) => ({
          id: result.v1_id,
          display_name: result.display_name,
          common_names: result.common_names,
          scientific_name: result.scientific_name,
          genus: result.genus,
          species: result.species,
        })) || []
      : initialOptions

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.display_name}
      sx={{ minWidth: 300 }}
      renderOption={(props: RenderOptionProps, option) => {
        const { key, ...optionsProps } = props
        return (
          <Box key={key} component="li" {...optionsProps}>
            <HtmlTooltip
              arrow
              enterDelay={0}
              placement="left"
              title={
                <p>
                  <span>
                    Genus:{' '}
                    <span className="text-sm italic text-zinc-800/85">
                      {option.genus || 'N/A'}
                    </span>
                  </span>
                  <br />
                  <span>
                    Species:{' '}
                    <span className="text-sm italic text-zinc-800/85">
                      {option.species || 'N/A'}
                    </span>
                  </span>
                  <br />
                  <span>
                    Scientific Name:{' '}
                    <span className="text-sm italic text-zinc-800/85">
                      {option.scientific_name || 'N/A'}
                    </span>
                  </span>
                  <br />
                  <span>
                    Common Names:{' '}
                    <span className="text-xs text-zinc-800/75">
                      {option.common_names.join(', ') || 'N/A'}
                    </span>
                  </span>
                </p>
              }
            >
              <Box className="w-full">
                <Typography>{option.display_name}</Typography>
                <Typography variant="caption" className="truncate">
                  {option.common_names.join(', ')}
                </Typography>
              </Box>
            </HtmlTooltip>
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select or search for a V1 plant"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(isLoading || isSearching) && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      loading={isLoading || isSearching}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(_event, newValue) => {
        onChange(newValue)
      }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
    />
  )
}
