import axios from 'axios'
import { API_BASE_URL } from '@/constants.ts'
import { logError } from '@/api/utils/logger.ts'

let getAccessTokenSilently: () => Promise<string>
let loginWithRedirect: () => void

export const initializeApi = (getToken: () => Promise<string>) => {
  getAccessTokenSilently = getToken
}

export const refreshLogin = (login: () => void) => {
  loginWithRedirect = login
}

export const handleApiError = (error: any) => {
  if (error.response && error.response.status === 401) {
    loginWithRedirect()
  } else {
    logError(error)
  }
  return Promise.reject(error)
}

const api = axios.create({
  baseURL: API_BASE_URL,
})

api.interceptors.request.use(
  async (config) => {
    if (!getAccessTokenSilently) {
      console.error('Auth0 not initialized. Call initializeApi first.')
      return config
    }

    try {
      const token = await getAccessTokenSilently()
      config.headers['Authorization'] = `Bearer ${token}`
    } catch (error) {
      console.error('Error getting access token', error)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use((response) => response, handleApiError)

export default api
