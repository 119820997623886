import { useNavigate } from '@tanstack/react-router'

import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import type { Contact, ContactType } from '@/types.ts'
import { ContactTypes } from '@/constants.ts'

type ContactsTableProps = {
  type: ContactType
  loading: boolean
  contacts: Contact[] | undefined
}
export default function ContactsTable({
  type,
  loading,
  contacts,
}: ContactsTableProps) {
  const navigate = useNavigate()

  const handleRowClick = async (contactId: string) => {
    await navigate({ to: `/contacts/${contactId}` })
  }

  const tableHead =
    type === ContactTypes.USER ? (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Organization</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
        </TableRow>
      </TableHead>
    ) : (
      <TableHead>
        <TableRow>
          <TableCell>Organization</TableCell>
          <TableCell>Zip</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Phone</TableCell>
        </TableRow>
      </TableHead>
    )

  // create some loading skeleton rows for loading state
  const skeletonRows = Array.from({ length: 5 }).map((_, index) => (
    <TableRow key={index}>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
    </TableRow>
  ))

  const emptyTable = (
    <TableRow>
      <TableCell colSpan={4}>
        <Typography variant="h6" align="center">
          No contacts found
        </Typography>
      </TableCell>
    </TableRow>
  )

  const tableRows = contacts?.map((contact) => (
    <TableRow
      key={contact.id}
      onClick={() => handleRowClick(contact.id)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
        },
      }}
    >
      <TableCell>
        {type === ContactTypes.USER
          ? `${contact.first_name} ${contact.last_name || ''}`
          : contact.organization_name}
      </TableCell>
      <TableCell>
        {type === ContactTypes.USER
          ? contact.organization_contact?.[0]?.organization_name
          : contact.address?.[0]?.address.zip}
      </TableCell>
      <TableCell>{contact.email?.[0]?.address}</TableCell>
      <TableCell>{contact.phone?.[0]?.number}</TableCell>
    </TableRow>
  ))

  return (
    <TableContainer>
      <Table aria-label="contacts table">
        {tableHead}
        <TableBody>
          {loading && skeletonRows}
          {!loading && contacts?.length === 0 && emptyTable}
          {!loading && tableRows}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
